#footer {
    background-color: #1a1a1a;
    height: 15vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrCodeImg {
    width: 12vh;
    height: 12vh;
}

.qrCodeDesc {
    color: white;
    margin-right: 10px;
}

@media screen and (max-width: 600px) {
    #footer {
        width: 100%;
    }
}