#header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 50px;
    width: 50px;
    padding-left: 20px;
}
.logo:hover {
    cursor: pointer;
}

.languageSelector {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.leftCorner {
    order: 1; /* Ensure it's the first item on the left */
}

.rightCorner {
    order: 2; /* Ensure it's the second item on the right */
    display: flex;
    align-items: center;
}

body {
    border: 0;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: rgba(250, 250, 250)
}

.buttonContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

button {
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    margin: 0 10px;
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
}

.reg {
    color: white;
    background-color: rgba(104, 85, 224, 1);
    width: 80px;
    border-radius: 999px;
}

.log {
    color: rgb(104, 85, 224);
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(104, 85, 224, 1);
    width: 80px;
    border-radius: 999px;
}

button:hover {
    color: white;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
    background-color: rgba(104, 85, 224, 1);
}

