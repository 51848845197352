.modalInput {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.modalRight {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.inputDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 70%;
    margin: auto;
}

#errorText {
    display: none;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 0;
}