.rootDiv {
  position: relative;
  height: 85vh;
}

.titleDiv {
  text-align: center; /* Center the text inside titleDiv */
  margin-bottom: 20px;
}

.searchBarDiv {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.smallLogo {
  width: 50px;
  height: 50px;
  align-self: center;
  margin-right: 10px;
}

.wordImgDiv {
  margin-top: 30px;
  width: 350px;
}

.imgAndTitleDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttonDiv {
  margin: 0;
  position: absolute;
  top: 80%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 600px) {
  .rootDiv {
  }

  .titleDiv {
    width: 100%;
  }

  .searchBarDiv {
    display: flex;
    justify-content: center;
    width: 100%;

    position: static; /* Reset position to normal flow */
    transform: none; /* Reset transform */
    top: auto; /* Reset top value */
    left: auto; /* Reset left value */
  }

  .buttonDiv {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    width: 100%;

    position: static; /* Reset position to normal flow */
    transform: none; /* Reset transform */
    top: auto; /* Reset top value */
    left: auto; /* Reset left value */
  }

}