.defDiv {
    margin-top: 40px;
}

.searchBar {
    width: 800px;
}

@media screen and (max-width: 600px) {
    .searchBar {
        width: 80%;
    }
    .defDiv {
        margin-top: 80px;
    }
}